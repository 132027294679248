.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  :root {
    --secondary-color: #ff9900;
    --background-color: #232f3e;
  }
  
  .header-container {
    
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 25px 0;
    width: 100%;
  }

  .notice-container {
    align-items: left;
    text-align: left;
    width: 50%;
    margin:auto;
    margin-top: 10px;
    background-color: #ff9900;
    padding: 10px;
  }

  .notice-container a {
    color: #232f3e;
  }

  .product-notice-container a {
    color: #232f3e;
  }

  .product-notice-container {
    color: #000000;
    align-items: left;
    text-align: left;
    margin:auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #ff9900;
    padding: 10px;
  }

  .header-thinkbox-marketplace-text {
    color: white;
    cursor: pointer;
  }
  
  .header-cart {
    color: white;
    margin-right: 3rem;
    cursor: pointer;
  }
  
  .header-sign-in-create-account {
    color: white;
    display: flex;
    justify-content: space-between;
    margin-left: 3rem;
    cursor: pointer;
  }
  
  .navigation-bar {
    text-align: center;
    white-space: nowrap;
    margin: 0;
    list-style-type: none;
  }
  
  .navigation-bar li {
    /* Used to make list horizontal */
    display: inline;
    margin: 1rem;
    color: white;
  }
  .navigation-bar a {
    text-decoration: none;
    color: white;
  }
  
  .content-container-section-name {
    color: white;
  }
  
  .content-flexbox {
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
  }
  .content-container-text {
    color: white;
    text-align: left;
    margin: 10rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .content-container-text a {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .banner-holder {
    width: 100%;
    display: flex;
    align-self: center;
    justify-self: center;
    align-content: space-between;
    justify-content: center;
  }
  
  .banner {
    flex-grow: 1;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    align-self: center;
    justify-self: center;
  }
  
  .banner hr {
    width: 100%;
  }
  
  .banner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .banner-section {
    display: flex;
  }
  
  .banner-section-name {
    color: white;
  }
  
  .quick-links {
    color: white;
    text-align: left;
  }
  
  .quick-links p {
    margin: 7px;
  }
  
  .get-connected {
    color: var(--secondary-color);
    text-align: left;
    padding: 0px;
  }
  
  .get-connected p {
    margin: 7px;
  }
  
  .link-container {
    color: white;
    text-align: left;
    margin: 0px;
  }
  
  .link-container a {
    text-decoration: none;
    color: var(--secondary-color);
  }
  
  .link-container p {
    margin: 7px;
  }
  
  .newsletter {
    color: white;
    text-align: left;
  }
  
  .content-container-list {
    color: white;
    list-style: disc outside;
  }
  
  .content-container {
    max-width: 800px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .requirements-list  {
    margin-bottom: 0.4rem;
    color: white;
    text-align: left;
    margin-left: 10rem;
    margin-right: 10rem;
  }

   .requirements-list li {
    margin-bottom: 1rem;
  } 

  .product-requirements-container {
    width: 100%;
  }
  .product-requirements-header {
    text-align: left;
    margin-left: 10rem;
    color: white;
  }
  
  .license-box-view {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .license-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    cursor: pointer;
  }
  .white-text {
    color: white;
  }
  
  .license-image-container {
    display: flex;
    align-items: center;
    height: 200px;
  }

  .collection-image {
    max-width: 100%;
    width: 200px;
  }
  
  .license-view-container {
    color: white;
    max-width: 70%;
    max-width: 800px;
  }
  
  .license-hours-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .license-hours-item {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    max-width: 200px;
    cursor: pointer;
  }
  
  .license-hours-item img {
    width: 100%;
    height: auto;
  }
  
  .license-hours-item p {
    color: #aebcff;
    font-size: 1.1rem;
  }
  
  .clickable {
    cursor: pointer;
  }

  .clickable-link {
    cursor: pointer;
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .back-button-container {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .back-button-container button {
    left: 0;
    color: white;
    background-color: var(--background-color);
    border: none;
    padding-right: 1rem;
  }
  
  .license-name-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-info-container {
    display: flex;
    color: white;
    flex-direction: column;
    margin-left: 20%;
    margin-right: 20%;
    max-width: 800px;
  }
  
  .product-info-container p {
    text-align: left;
  }
  
  .product-image {
    max-width: 50%;
    height: auto;
  }

  .credentials-form {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form-label {
    display: block;
    text-align: left;
  }
  .form-field {
    width: 200px;
    display: block;
    margin: auto;
  }

  .cart-labels {
    font-size: large;
    display: grid;
    width: 100%;
    color: white;
    grid-template-columns: 60% 30% 10%;
  }

  .cart-item-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid white;
    
  }

  .cart-item-container {
    display: grid;
    grid-template-columns: 60% 30% 10%;
    grid-template-rows: 1fr auto 1fr;
    border-top: 1px solid white;
    margin: 1px;
    height: 150px;
    
  }
  
  .cart-item-name {
    color: white;
  }
  .cart-item-notice {
    color: #ff0000;
    font-size: 12px;
  }

  .cart-item-remove {
    color: var(--secondary-color);
    cursor: pointer;
  }

  .cart-item-image {
    width: auto;
    height: 8rem;
  }

  .cart-item-sub-box {
    grid-row-start: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .cart-item-name-remove {
    text-align: left;
  }

  .cart-item-quantity {
    grid-column-start: 2;
    grid-row-start: 2;
    align-self: center;
    justify-self: center;
    color: white;
  }

  .cart-item-total {
    grid-column-start: 3;
    grid-row-start: 2;
    align-self: center;
    color: white;
  }

  .cart-sub-total {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
  }

  .add-to-cart-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .quantity-counter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 25px;
    margin: 0px;
    border: solid 1px white;
  }
  .quantity-counter {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
  .quantity-decrementer {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: 0px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border-right: solid 1px white;
  }
  .quantity-incrementer {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    margin: 0px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    border-left: solid 1px white;
  }
  .terms-of-use {
    max-width: 1335px;
    width: 70%;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
  }
  .error-text {
    color: red;
  }
  
  .account-info-container {
    display: flex;
    flex-direction: column;
    color: white;
  }

  .pending-orders-table {
      display: inline-block;
      border-collapse: collapse;
      margin: 25px;
      max-height: 500px;
      border: 1px solid white;
      overflow: auto;
    }
  
  .order-history-table {
    display: block;
    border-collapse: collapse;
    margin: 25px;
    max-height: 500px;
    border: 1px solid white;
    overflow: auto;
  }
  .order-history-table tr,th,td {
    border: 1px solid white;
    padding: 10px;
  }

  .centered-flexbox {
    display: flex;
    justify-content: center;
  }
  .account-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .account-details-container h3, p {
    margin: 0px;
    padding: 0px;
  }
  .orange-button {
    background-color: var(--secondary-color);
    color: white;
    border-radius: 3px;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 15px;
    border: 1px solid transparent;
  }

  .authentication-button {
    margin-top: 10px;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 3px;
    font-weight: bold;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 15px;
    border: 1px solid transparent;
  }

  .modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #888;
    color: black;
    width: 50%; 
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    color: black;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 50%; ;
  }

  
  .close {
    color: #aaa;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .invisible-link {
    text-decoration: none;
  }

  .faq-container-text {
    color: white;
    text-align: left;
    width: 100%;
  }

  .workflow-text {
    color: white;
    margin: 0px;
  }